<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div class="contact">
    <!-- 查询条件 -->
    <div class="filter-container">
      <el-card shadow="never" v-show="showSearch">
        <el-form class="search-form" size="small" ref="searchForm" label-position="right" :inline="true">
          <!-- 生成搜索框 -->
          <el-form-item v-for="(search, index) in searchOptions" :key="index" :label="search.label">
            <el-select v-if="search.el === 'select'" v-model="search.value" :disabled="search.disabled"
              :placeholder="search.placeholder || $t('common.selectPlaceholder')" :clearable="search.clearable" filterable
              style="width: 100%;">
              <el-option v-for="(item, index) in search.list" :key="index" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-else v-model="search.value" :placeholder="search.placeholder || $t('common.inputPlaceholder')"
              :clearable="search.clearable" :disabled="search.disabled" />
          </el-form-item>
          <el-form-item :label="' '">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              {{ $t('operate.search') }}</el-button>
            <el-button icon="el-icon-refresh" @click="resetSearch">{{ $t('operate.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- 操作符 -->
    <el-card shadow="never">
      <el-row type="flex" justify="space-between" class="mb10">
        <el-col :span="24">
          <!-- 生成操作按钮 -->
          <template v-for="(operate, index) in topOperates">
            <el-button :key="index" v-if="operate.show" :type="operate.type" plain :icon="operate.icon" size="mini"
              @click="runBtnEvent(operate.click)">
              {{ operate.label }}
            </el-button>
          </template>
        </el-col>
      </el-row>
      <!--  表格数据  -->
      <el-table :data="tableData" style="width: 100% ;" @selection-change="handleSelectionChange" border>
        <!-- 选框 -->
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column type="index" :index="tableIndexMethod" label="#" width="50" align="center" />
        <!-- 生成Table列 -->
        <template v-for="(column, index) in columns">
          <el-table-column v-if="column.show" :key="index" :prop="column.prop" :label="column.label"
            :show-overflow-tooltip="column.overflow" :min-width="column.width || '100'"
            :align="column.align || 'center'" />
        </template>
        <el-table-column v-if="showColumnOperates" :label="$t('operate.operate')" align="center" min-width="120">
          <template slot-scope="scope">
            <template v-for="(operate, index) in columnOperates">
              <el-button :key="index" :type="operate.type || 'text'" size="small" :icon="operate.icon"
                @click="runBtnEvent(operate.click, scope.row)">
                {{ operate.label }}
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="block mt10" style="text-align:center">
        <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
          layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
    <!-- 添加或修改弹窗 -->
    <el-dialog :title="configsForm.title" :visible.sync="configsForm.show" :showClose="false"
      :close-on-click-modal="false">
      <el-form :ref="configsForm.ref" :model="queryForm" :label-width="configsForm.labelWidth || '120px'"
        :label-position="configsForm.labelPosition || 'right'" :disabled="configsForm.disabled" :rules="rules"
        size="small">
        <el-row>
          <!-- 生成表单 -->
          <el-col v-for="(column, index) in columnsForm" :key="index" :sm="24" :md="24" :lg="12">
            <el-form-item v-if="column.show" :label="column.label" :prop="column.prop">
              <el-select v-if="column.el === 'select'" v-model="queryForm[column.prop]"
                :placeholder="$t('common.selectPlaceholder')" :clearable="column.clearable" filterable
                style="width: 100%">
                <el-option v-for="(item, index) in column.list" :key="index" :label="item.label" :value="item.value" />
              </el-select>
              <el-input v-else :type="column.type || 'text'" v-model="queryForm[column.prop]" :disabled="column.disabled"
                :clearable="column.clearable">
                <span v-if="column.suffix" slot="suffix" class="el-input__icon" v-html="column.suffix"></span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!configsForm.disabled" type="primary" size="small" @click="submitForm()">{{
          $t('operate.save') }}</el-button>
        <el-button size="small" @click="handleCancel">{{ $t('operate.cancel') }}</el-button>
      </div>
    </el-dialog>
    <!-- 导入表单 -->
    <el-dialog :title="$t('contact.title.import')" :visible.sync="importForm.show" :showClose="false"
      :close-on-click-modal="false">
      <el-form ref="importExcel" label-width="120px" label-position="top" size="small">
        <el-form-item>
          <el-upload ref="uploadExcel" class="upload-demo" action="" :http-request="importExcelRequest"
            :before-upload="beforeAvatarUpload">
            <div class="el-upload__text">
              <el-button size="small" type="primary"><em>{{ $t('contact.label.upload') }}</em></el-button>
            </div>
            <div slot="tip" class="el-upload__tip">
              {{ $t('contact.message.importTip') }},
              <el-link type="primary" @click="$refs.uploadExcel.clearFiles()" :underline="false">
                {{ $t('contact.message.clearFiles') }}
              </el-link>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-input type="textarea" :rows="4" :value="importForm.textMessage"
            :placeholder="$t('contact.message.SysReturn')">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" size="small" @click="getImportTemplateExcel">{{ $t('contact.message.download')
        }}</el-button>
        <el-button size="small" @click="closeImportForm()">{{ $t('operate.cancel') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from '@/utils'
export default {
  name: 'Contact',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      searchOptions: [],
      //table上方按钮
      topOperates: [],
      // table columns 列表
      columns: [],
      // table columns 操作按钮
      showColumnOperates: true,
      columnOperates: [],
      // form表单配置
      configsForm: {
        title: '',
        type: '',
        ref: 'queryForm',
        // 控制表单弹框
        show: false,
        // 是否可以编辑
        disabled: false,
        labelWidth: '120px',
        labelPosition: 'right'
      },
      columnsForm: [],
      // 表单规则
      rules: {},
      // 弹窗标题
      title: {},
      queryForm: {},
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认20条
        pageSize: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      // 总记录数
      total: 0,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
      },
      importForm: {
        show: false,
        textMessage: ""
      },
      // 记录选中行信息
      selectRow: []
    }
  },
  computed: {

  },
  created() {
    // 获取请求参数，修改字段语言
    this.changeLocale()
    // 获取列表数据
    this.getTableData()
    // 页面渲染
    this.initElement()
  },
  methods: {
    initElement() {
      this.searchOptions = [
        { el: 'input', type: 'text', prop: 'name', label: this.$t('contact.label.name'), value: undefined, list: [], placeholder: undefined, clearable: true, disabled: false },
        { el: 'input', type: 'text', prop: 'phoneNumber', label: this.$t('contact.label.phoneNumber'), value: undefined, list: [], placeholder: undefined, clearable: true, disabled: false },
      ];
      //table上面操作按钮
      this.topOperates = [
        { type: 'primary', label: this.$t('operate.register'), click: "handleAdd", icon: 'el-icon-plus', disabled: false, show: true },
        { type: 'success', label: this.$t('operate.edit'), click: "handleEdit", icon: 'el-icon-edit', disabled: false, show: true },
        { type: 'danger', label: this.$t('operate.delete'), click: "handleDelete", icon: 'el-icon-delete', disabled: false, show: true },
        { type: 'warning', label: this.$t('operate.export'), click: "handleExport", icon: 'el-icon-download', disabled: false, show: true },
        { type: 'primary', label: this.$t('operate.import'), click: "handleImport", icon: 'el-icon-upload', disabled: false, show: true },
      ];
      this.columns = [
        { prop: 'name', label: this.$t('contact.label.name'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'phoneNumber', label: this.$t('contact.label.phoneNumber'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'wechatId', label: this.$t('contact.label.wechatId'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'emailAddress', label: this.$t('contact.label.emailAddress'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'remark', label: this.$t('contact.label.remark'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'registeredTime', label: this.$t('contact.label.registeredTime'), width: '100', align: 'center', overflow: true, show: true },
      ];
      this.columnOperates = [
        { type: 'text', label: this.$t('operate.look'), click: "handleDetail", icon: 'el-icon-notebook-2', disabled: false, show: true },
        { type: 'text', label: this.$t('operate.edit'), click: "handleUpdate", icon: 'el-icon-edit', disabled: false, show: true },
        { type: 'text', label: this.$t('operate.delete'), click: "deleteonSubmit", icon: 'el-icon-delete', disabled: false, show: true },
      ];
      this.columnsForm = [
        { el: 'input', type: 'text', prop: 'id', label: this.$t('contact.label.id'), value: undefined, list: [], clearable: true, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'name', label: this.$t('contact.label.name'), value: undefined, list: [], clearable: true, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'phoneNumber', label: this.$t('contact.label.phoneNumber'), value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'mobileNumber', label: this.$t('contact.label.mobileNumber'), value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'wechatId', label: this.$t('contact.label.wechatId'), suffix: undefined, value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'emailAddress', label: this.$t('contact.label.emailAddress'), suffix: undefined, value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'remark', label: this.$t('contact.label.remark'), value: undefined, list: [], clearable: true, disabled: false, show: true },
      ];
      this.rules = {
        id: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
        name: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
      }
      this.title = {
        info: this.$t('contact.title.info'),
        save: this.$t('contact.title.save'),
        edit: this.$t('contact.title.edit'),
      }
    },
    /**
    * @methods tableIndexMethod
    * @description 设置table的Index
    * @param {Object} params index 序号默认值
    */
    tableIndexMethod(index) {
      return ((this.queryParams.pageIndex - 1) * this.queryParams.pageSize) + (index + 1)
    },
    /**
    * @methods runBtnEvent
    * @description 动态调用执行方法
    * @param click 执行方法名
    * @param row 传值数据
    */
    runBtnEvent(click, row) {
      this[click](row)
    },
    importExcelRequest(data) {
      this.$api.importContactsExcelFile(data.file).then(res => {
        this.importForm.textMessage = res.msg;
        if (res?.data) {
          const data = res?.data || [];
          this.importForm.textMessage = data.join("\n");
        }
      }).catch(() => {
        this.$message({
          message: 'upload error',
          type: 'error'
        })
      })
    },
    closeImportForm() {
      this.importForm.show = false;
      this.$refs.uploadExcel.clearFiles();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.importForm.textMessage = "上传失败，上传文件大小不能超过 20MB!";
        this.$message.error('上传文件大小不能超过 20MB!');
      }
      if (!isJPG) {
        this.importForm.textMessage = "上传失败，上传文件只能是 xlxs 格式!";
        this.$message.error('上传文件只能是 xlxs 格式!');
      }
      return isJPG && isLt10M;
    },
    /**
    * @methods getImportTemplateExcel
    * @description 获取导入模版
    */
    getImportTemplateExcel: debounce(function () {
      this.$api.downloadContactExcelFile().then(res => {
        this.$api.commonDownload(res.msg);
      })
    }, 200),
    /**
    * @methods getTableData
    * @description 获取列表数据
    */
    getTableData: debounce(function () {
      const queryParams = this.queryParams
      this.searchOptions.forEach(search => {
        if (!search.disabled) {
          this.$set(queryParams, search.prop, search.value)
        }
      })
      // 发送请求数据
      this.$api.getContactList(queryParams).then(res => {
        // 拿到结果先判断机器类型
        res.data.list.forEach(item => {
          item.registeredTime = this.changeTime(item.registeredTime)
          item.updatedTime = this.changeTime(item.updatedTime)
        })
        this.tableData = res.data.list
        this.total = res.data.total
      })
    }, 200, true),
    /**
    * @methods resetQuery
    * @description 重置查询条件
    */
    resetQuery() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.searchOptions.forEach(item => {
        item.value = undefined
      })
    },
    /**
    * @methods changeLocale
    * @description 修改语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods handleSizeChange
    * @description 点击修改每页最大数触发
    * @param {Object} params val 表示一页需要多少条数，pageSize
    */
    handleSizeChange(val) {
      this.queryParams.pageSize = `${val}`
      this.getTableData()
    },
    /**
    * @methods handleCurrentChange
    * @description 点击切换页数触发
    * @param {Object} params val 表示当前第几页,pageIndex
    */
    handleCurrentChange(val) {
      this.queryParams.pageIndex = `${val}`
      this.getTableData()
    },
    /**
    * @methods handleSelectionChange
    * @description 使用选框时触发
    * @param {Object} params val 表示选中的信息
    */
    handleSelectionChange(val) {
      this.selectRow = val
    },
    handleImport() {
      this.importForm.show = true
    },
    /**
    * @methods handleUpdate
    * @description 编辑处理
    * @param {Object} params row 表示某一行数据
    */
    handleUpdate(row) {
      // 配置表单
      const isDisabled = ['id']
      this.columnsForm.forEach(item => {
        const prop = item.prop
        if (prop.indexOf(isDisabled) >= 0) {
          item.disabled = true
        }
        const value = row[prop]
        this.$set(this.queryForm, prop, value)
      })
      // 弹窗配置
      this.configsForm.type = 'edit'
      this.configsForm.title = this.title.edit
      this.configsForm.show = true
      this.configsForm.disabled = false
    },
    /**
    * @methods handleEdit
    * @description 点击表格上方的编辑按钮触发
    */
    handleEdit() {
      if (this.selectRow.length !== 1) {
        this.$message({
          type: 'error',
          message: this.$t('message.edit.tips')
        })
      } else {
        this.handleUpdate(this.selectRow[0])
      }
    },
    /**
    * @methods handleAdd
    * @description 点击新增按钮触发
    */
    handleAdd() {
      // 配置表单
      this.columnsForm.forEach(item => {
        if (item.disabled) {
          item.disabled = false;
        }
        this.$set(this.queryForm, item.prop, item.value);
      })
      // 配置弹窗
      this.configsForm.type = 'add';
      this.configsForm.show = true;
      this.configsForm.disabled = false;
      this.configsForm.title = this.title.save;
    },
    /**
    * @methods submitForm
    * @description 编辑和新增的表单确认按钮
    * @param {Object} params formName 表单名字
    */
    submitForm: debounce(function () {
      const formName = this.configsForm.ref
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 发送请求编辑或新增
          if (this.configsForm.type === 'add') {
            // 发送请求新增服务器信息
            this.queryForm.time = this.newTime()
            this.$api.saveContact(this.queryForm).then(res => {
              let code = res?.code;
              const msg = res?.data;
              this.showTipes(code, msg || this.$t('message.add.success'), msg || this.$t('message.add.fail'))
              if (code === 1) {
                this.handleCancel();
              }
            })
          } else if (this.configsForm.type === 'edit') {
            this.queryForm.time = this.newTime()
            // 发送请求修改数据
            this.$api.updateContact(this.queryForm).then(res => {
              let code = res?.code;
              const msg = res?.data;
              this.showTipes(code, msg || this.$t('message.edit.success'), msg || this.$t('message.edit.fail'))
              if (code === 1) {
                // 关闭弹窗
                this.configsForm.show = false
                // 重置表单
                this.resetForm()
              }
            })
          }
        } else {
          return false
        }
      })
    }, 200),
    resetForm() {
      this.queryForm = {}
    },
    /**
    * @methods handleCancel
    * @description 编辑和注册的取消按钮
    */
    handleCancel() {
      // 关闭表单
      this.configsForm.show = false
      // 重置表单
      this.resetForm()
    },
    /**
    * @methods deleteonSubmit
    * @description 删除按钮
    * @param {Object} params row 当前行
    */
    deleteonSubmit(row) {
      // 确认删除提示框
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        // 发送请求删除当前行数据，重新getTableData()
        this.$api.deleteContactById(row.id).then((res) => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    },
    /**
    * @methods handleDelete
    * @description 用户按下删除按钮时触发
    */
    handleDelete: debounce(function () {
      const selectRow = this.selectRow
      // 判断是否选中行
      if (!selectRow || selectRow.length === 0) {
        this.$message({
          type: 'error',
          message: this.$t('message.delete.tips')
        })
        return
      }
      let arrVal = []
      selectRow.forEach(item => {
        arrVal.push(item.id)
      });
      const strVal = arrVal.join(',');
      // 确认删除提示框
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        this.$api.deleteContactById(strVal).then(res => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    }, 200),
    /**
    * @methods handleSearch
    * @description 搜索按钮
    */
    handleSearch: debounce(function () {
      this.queryParams.pageIndex = 1
      this.getTableData()
    }, 200),
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getTableData()
    },
    /**
    * @methods handleDetail
    * @description 查看详情按钮
    * @param {Object} params row 当前行
    */
    handleDetail(row) {
      this.columnsForm.forEach(item => {
        const value = row[item.prop] || undefined
        this.$set(this.queryForm, item.prop, value)
      })
      this.configsForm.show = true;
      this.configsForm.disabled = true;
      this.configsForm.title = this.title.info;
    },
    /**
    * @methods handleExport
    * @description 导出按钮
    */
    handleExport: debounce(function () {
      this.$api.exportContactsAsExcelFile(this.queryParams).then(res => {
        this.exportFile(this.title.info, res)
      })
    }, 200),
    /**
    * @methods showTipes
    * @description 弹框提示信息
    * @param {Object} params 错误码、提示信息对应的i18n字段
    */
    showTipes(code, sucessMessage, errorMessage) {
      if (code === 1) {
        this.resetQuery()
        this.getTableData()
        this.$message({
          type: 'success',
          message: sucessMessage
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0.625rem 0.625rem 0.25rem;
  background-color: #f8f8f8;
  border-bottom: 0.0625rem solid #eee;
  overflow: hidden;
}

::v-deep .el-dialog__headerbtn {
  top: 0.9375rem;
}

::v-deep .el-dialog__body {
  padding: 1.875rem 1.25rem;
}


::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}
</style>
